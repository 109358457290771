.wrapper {
    overflow: hidden;
    border-radius: 20px;
    background-color: var(--background-global);
    padding: 8px;
    z-index: 2;
    position: relative;
}

.header {
    padding: 16px;
}

.chip {
    margin-bottom: 12px;
}

.title {
    font-size: 32px;
    font-weight: 600;
    line-height: 32px;
    letter-spacing: -0.01em;
    text-align: left;
    font-variation-settings: 'GRAD' 0, 'XOPQ' 96, 'XTRA' 468, 'YOPQ' 79, 'YTAS' 750, 'YTDE' -203, 'YTFI' 738, 'YTLC' 514, 'YTUC' 712, 'slnt' 0, 'wdth' 25;
    color: var(--switcher-active-color)
}

.rating {
    margin-top: 12px;
    display: flex;
    align-items: center;
}

.number {
    font-family: 'Inter';
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    text-align: center;
    margin-right: 4px;
    color: var(--bright-text-color)
}

.start {
    margin-right: 4px;
}

.reviews {
    font-family: 'Inter';
    font-size: 12px;
    font-weight: 400;
    line-height: 15.6px;
    text-align: center;
    color: var(--switcher-disabled-color)
}

.description {
    padding-left: 16px;
    padding-right: 16px;
}

.list {
    margin-top: 24px;
}

.corseButton {
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
    text-decoration: none;

    font-family: 'Inter';
    font-size: 16px;
    font-weight: 400;
    line-height: 22.4px;
    text-align: center;
    color: var(--switcher-active-color)
}

.arrow {
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
    width: 14.5px;
    position: relative;
    margin-left: 6px;
    transform: rotate(-90deg);
    margin-left: 8px;
    min-width: max-content;
}

.arrow:after{
    content: '';
    width: 100%;
    width: 9px;
    height: 1.5px;
    background-color: var(--banner-count-background-color);
    position: absolute;
    left: 0;
    transform: rotate(45deg);
}

.arrow:before{
    content: '';
    width: 100%;
    width: 9px;
    height: 1.5px;
    background-color: var(--banner-count-background-color);
    position: absolute;
    right: 0;
    transform: rotate(-45deg);
}

.loader {
    width: 22px;
    height: 22px;
    animation: rotation 2s linear infinite;
}

.loader svg {
    width: 100%;
    height: 100%;
}

.curtain {
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
   align-content: center; 
   padding-left: 6px;
   padding-right: 6px;
   padding-bottom: 28px;
   position: relative;
}

.curtainLoader {
    width: 65px;
    height: 65px;
    animation: rotation 3s linear infinite;
    margin-bottom: 50px;
    margin-top: 66px;
}

.curtainLoader svg {
    width: 100%;
    height: 100%;
}

.curtainTitle {
    font-family: 'Inter';
    font-size: 22px;
    font-weight: 600;
    line-height: 26px;
    text-align: center;
    max-width: 280px;
    margin-bottom: 6px;
    color: var(--bright-text-color)
}

.curtainSubtitle {
    font-family: 'Inter';
    font-size: 14px;
    font-weight: 400;
    line-height: 16.71px;
    max-width: 280px;
    text-align: center;
    color: var(--disabled-button-text);
    margin-bottom: 32px;
}

.button {
    width: 100%;
    height: 54px;
    background-color: var(--banner-count-background-color);
    color: var(--bright-text-color);
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
    text-decoration: none;
    font-size: 16px;
    font-weight: 600;
    line-height: 22.4px;
    border-radius: 100px;
}

.closeButton {
    position: absolute;
    top: -6px;
    right: 16px;
    height: 16px;
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 30px;
    cursor: pointer;
    background-color: var(--main-cards-bg);
}

.closeButton:after{
    content: '';
    width: 100%;
    width: 12px;
    height: 1.5px;
    background-color: var(--bright-text-color);
    position: absolute;
    left: 6px;
    transform: rotate(45deg);
}

.closeButton:before{
    content: '';
    width: 100%;
    width: 12px;
    height: 1.5px;
    background-color: var(--bright-text-color);
    position: absolute;
    right: 6px;
    transform: rotate(-45deg);
}

.curtainReject {
    width: 65px;
    height: 65px;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    margin-bottom: 50px;
    margin-top: 46px;
}

.curtainReject:after {
    content: '';
    width: 80px;
    height: 8px;
    position: absolute;
    display: block;
    border-radius: 10px;
    background-color: var(--bright-red);
    transform: rotate(45deg);
}

.curtainReject:before {
    content: '';
    width: 80px;
    height: 8px;
    position: absolute;
    display: block;
    border-radius: 10px;
    background-color: var(--bright-red);
    transform: rotate(-45deg);
}

.curtainSuccess {
    width: 65px;
    height: 65px;
    display: flex;
    align-items: center;
    align-content: center;
    position: relative;
    margin-bottom: 30px;
    margin-top: 46px;
}

.curtainSuccess:before {
    content: '';
    width: 36px;
    height: 8px;
    position: absolute;
    flex-shrink: 0;
    display: block;
    top: 28px;
    left: -8px;
    border-radius: 10px;
    background-color: var(--tag-dark-green);
    transform: rotate(45deg);
}

.curtainSuccess:after {
    content: '';
    width: 65px;
    height: 8px;
    position: absolute;
    flex-shrink: 0;
    top: 18px;
    right: -8px;
    display: block;
    border-radius: 10px;
    background-color: var(--tag-dark-green);
    transform: rotate(-45deg);
}

@keyframes rotation {
    0% {
        transform: rotate(0);
    }

    100% {
        transform: rotate(360deg);
    }
}